<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 100 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5075 3.86788L11.7278 2.50822C11.5552 2.20642 11.6593 1.82009 11.9604 1.64628L14.6738 0.0835667C14.9742 -0.0894533 15.3605 0.0148325 15.5331 0.31663L16.3128 1.6763C18.5035 5.478 19.6151 9.73713 19.6151 14.0034C19.6151 18.2285 18.5248 22.4798 16.3128 26.3194L15.526 27.6846C15.3534 27.9856 14.9663 28.0899 14.6659 27.9161L9.39044 24.8633L9.38886 24.8657L6.12833 22.9735C5.82797 22.7989 5.7247 22.4118 5.89892 22.1108L6.68489 20.7504C7.91153 18.6212 8.49568 16.3008 8.49568 14.0152C8.49568 12.5331 8.25209 11.0644 7.78303 9.66918C7.74519 9.55858 7.66794 9.478 7.55836 9.43612C7.44878 9.39504 7.33763 9.40373 7.23593 9.46298L5.83901 10.2712C5.6845 10.3605 5.61434 10.5398 5.66716 10.7105C5.99431 11.7747 6.16302 12.887 6.16302 14.0073C6.16223 15.9034 5.68056 17.824 4.66834 19.5819L3.88868 20.9408C3.71604 21.2426 3.32976 21.3468 3.02861 21.1738L0.315182 19.6111C0.0148283 19.4381 -0.0892315 19.051 0.0834128 18.7492L0.863071 17.3895C1.47876 16.3214 1.77123 15.156 1.77123 14.0073C1.77123 12.8144 1.45747 11.638 0.86386 10.6046L0.0842019 9.24493C-0.0884424 8.94392 0.015617 8.5568 0.316759 8.38378L1.69712 7.58821L8.84886 3.45074C9.15 3.27614 9.53628 3.38043 9.70971 3.68222L10.4925 5.04426C12.0589 7.76913 12.8867 10.8708 12.8867 14.0152C12.8867 16.255 12.4681 18.5161 11.6001 20.6769C11.5292 20.8531 11.5978 21.0514 11.7617 21.1462L13.135 21.941C13.2328 21.9978 13.3392 22.0081 13.4456 21.9718C13.552 21.9354 13.6301 21.8627 13.6734 21.7577C14.6991 19.2738 15.2067 16.6374 15.2067 14.0034C15.2067 10.4782 14.2978 6.97592 12.5075 3.86788V3.86788Z"
      fill="#858585"
    />
    <path
      d="M87.5466 12.2454H94.6439C94.6021 11.2342 94.2553 10.4228 93.6033 9.81131C92.9514 9.19981 92.1205 8.89485 91.1114 8.89485C90.1015 8.89485 89.2817 9.21087 88.651 9.84291C88.0196 10.4749 87.6522 11.276 87.5466 12.2454ZM82.9727 14.0475C82.9727 11.5391 83.735 9.45815 85.2596 7.8038C86.7842 6.14944 88.7141 5.32227 91.0483 5.32227C93.5718 5.32227 95.5379 6.10757 96.9474 7.67739C98.3561 9.24721 99.0609 11.4025 99.0609 14.1423C99.0609 14.3272 99.0585 14.4923 99.0546 14.6369C99.0475 14.8897 98.841 15.0904 98.5887 15.0904H87.4204C87.4622 16.2075 87.8832 17.1192 88.6825 17.8255C89.4811 18.531 90.491 18.8842 91.7105 18.8842C93.2935 18.8842 94.539 18.3296 95.448 17.2204C95.6064 17.0268 95.8902 16.9928 96.0897 17.1445L98.2505 18.7862C98.4484 18.9371 98.4941 19.2168 98.353 19.423C97.869 20.1317 97.1264 20.8214 96.1268 21.4921C94.949 22.283 93.4559 22.678 91.6475 22.678C89.0397 22.678 86.9419 21.8721 85.3542 20.2597C83.7665 18.6472 82.9727 16.5765 82.9727 14.0475V14.0475Z"
      fill="#858585"
    />
    <path
      d="M77.0063 18.821C78.1092 18.821 79.1813 18.4544 80.2219 17.7228C80.3465 17.6351 80.4923 17.6138 80.6373 17.662C80.7816 17.7102 80.8857 17.8145 80.933 17.9598L81.793 20.5931C81.8569 20.7882 81.7915 20.9928 81.6267 21.1145C80.2164 22.1566 78.5916 22.678 76.754 22.678C74.1888 22.678 72.08 21.8666 70.4293 20.2439C68.7785 18.6211 67.9531 16.5559 67.9531 14.0475C67.9531 11.5391 68.7887 9.45815 70.4608 7.8038C72.1328 6.14944 74.2203 5.32227 76.7225 5.32227C78.5404 5.32227 80.1817 5.84291 81.6472 6.88498C81.8175 7.00664 81.8861 7.21679 81.819 7.4151L80.914 10.1147C80.8644 10.2616 80.7572 10.3659 80.6098 10.4117C80.4615 10.4576 80.3149 10.4315 80.1912 10.3383C79.1695 9.5656 78.0556 9.17927 76.8486 9.17927C75.4817 9.17927 74.3827 9.64303 73.5526 10.5697C72.7217 11.4973 72.3063 12.646 72.3063 14.0159C72.3063 15.4277 72.7477 16.582 73.6315 17.4771C74.5144 18.373 75.6393 18.821 77.0063 18.821V18.821Z"
      fill="#858585"
    />
    <path
      d="M61.5459 21.7998V6.20111C61.5459 5.94355 61.7556 5.7334 62.0127 5.7334H65.4337C65.6899 5.7334 65.8996 5.94355 65.8996 6.20111V21.7998C65.8996 22.0573 65.6899 22.2675 65.4337 22.2675H62.0127C61.7556 22.2675 61.5459 22.0573 61.5459 21.7998Z"
      fill="#858585"
    />
    <path
      d="M46.1163 14.0475C46.1163 15.4388 46.542 16.582 47.3942 17.4771C48.2456 18.373 49.3233 18.821 50.6272 18.821C51.9524 18.821 53.0402 18.373 53.8924 17.4771C54.7438 16.582 55.1695 15.4388 55.1695 14.0475C55.1695 12.6357 54.7383 11.4712 53.8767 10.5539C53.0142 9.63749 51.9311 9.17927 50.6272 9.17927C49.3233 9.17927 48.2456 9.63749 47.3942 10.5539C46.542 11.4712 46.1163 12.6357 46.1163 14.0475V14.0475ZM44.2709 20.2439C42.5988 18.6211 41.7632 16.5559 41.7632 14.0475C41.7632 11.5391 42.6098 9.45815 44.3024 7.8038C45.9957 6.14944 48.1037 5.32227 50.6272 5.32227C53.1506 5.32227 55.2641 6.15497 56.9677 7.8196C58.6713 9.48422 59.5227 11.5605 59.5227 14.0475C59.5227 16.5559 58.6871 18.6211 57.015 20.2439C55.343 21.8666 53.2137 22.678 50.6272 22.678C48.0619 22.678 45.9429 21.8666 44.2709 20.2439V20.2439Z"
      fill="#858585"
    />
    <path
      d="M35.5131 21.7998V12.5878C35.5131 11.2155 34.3961 10.096 33.0267 10.096H29.8356V21.7998C29.8356 22.0573 29.6259 22.2675 29.3689 22.2675H25.9483C25.6913 22.2675 25.4824 22.0573 25.4824 21.7998V10.096V6.20111C25.4824 5.94355 25.6913 5.7334 25.9483 5.7334H29.8356H33.495C37.0039 5.7334 39.8663 8.60205 39.8663 12.1193V21.7998C39.8663 22.0573 39.6574 22.2675 39.4004 22.2675H35.9798C35.7228 22.2675 35.5131 22.0573 35.5131 21.7998V21.7998Z"
      fill="#858585"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '80'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
